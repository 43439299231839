import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const SignIn = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <h1>SignIn</h1>
    </div>
  </Layout>
)

export default SignIn
